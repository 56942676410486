import React, {useEffect} from "react"

/** @jsx jsx */
import { jsx } from "@emotion/core"

import CustomLayout from "../components/customLayout"
const IndexPage = () => {

  useEffect(() => {
    // Update the document title using the browser AP
  }, []);

  return (
  <CustomLayout>
      <div css={{maxWidth: '1024px', padding: '2rem 4rem', margin: 'auto'}}>
          <h3 css={{ fontSize: "1.5rem", fontWeight: 700 }}>GDPR</h3>
          <p>
            Dataskyddsförordningen, GDPR (General Data Protection Regulation) Ny
            lag Från den 25 maj 2018 gäller EU:s dataskyddsförordning fullt ut.
            Dataskyddsförordningen gäller som lag i Sverige.
            <br />
            <br />
            <strong>Personbiträde</strong>
            <br />
            Clearly AB behandlar personuppgifter för våra kunders räkning. Vi
            biträder våra kunder med att utföra hantering av löner. Vi är därmed
            personuppgiftsbiträde åt våra kunder. Våra kunder är
            personuppgiftsansvariga. Därför har vi upprättat nya avtal mellan
            Clearly AB och Clearly AB befintliga kunder. Det är uppdaterade
            villkor i uppdragsbrevet, nya allmänna villkor samt ett nytt
            personuppgiftsbiträdesavtal. För alla nya kunder upprättar vi, från
            och med 1 maj 2018, avtal med de nya villkoren samt
            personuppgiftsbiträdesavtal.
            <br />
            <br />
            <strong>Underbiträde</strong>
            <br />
            Clearly AB anlitar underbiträden vilka är delaktiga i behandlingen
            av personuppgifter. Det är leverantörer som för vår räkning lagrar
            datainformation i det s.k. molnet. Följande underbiträden anlitas av
            Clearly AB; Visma SPCS AB, Växjö
            <br />
            <br />
            <strong>Kategorier av personuppgifter</strong>
            <br />
            De personuppgifter som Clearly AB kommer att behandla är av följande
            slag: kontaktuppgifter som t.ex. namn, adress, telefonnummer och
            e-postadresser, uppgifter om anställning som t.ex.
            anställningsnummer, avdelningstillhörighet, befattning och
            anställningstid, uppgifter om hälsa och frånvaro, t.ex. läkarintyg
            och uppgifter om sjukfrånvaro, tjänstledighet eller
            föräldraledighet, facklig tillhörighet, uppgift om medlemskap i
            Svenska kyrkan (kyrkoskatt), Personnummer/samordningsnummer och
            uppgifter om ekonomiska förhållanden som t.ex. bankkontouppgifter,
            uppgifter om lön och andra förmåner, försäkringsuppgifter,
            fordonsuppgifter, bankkontonummer, och löneuppgifter (lönelistor).
          </p>
          <h4 css={{ fontSize: "1.5rem", fontWeight: 700 }}>Integritetspolicy</h4>
          <p>
            <strong>Systemsäkerhet</strong>
            <br />
            Samtliga tjänster uppfyller Dataskyddsförordningens krav på
            hantering av personuppgifter. Vi arbetar proaktivt med att förhindra
            obehörig åtkomst till systemen. Tillsammans med en tredjepart utför
            vi kontroller på våra system för att hitta och rätta till eventuella
            brister som påpekas. Vi förbättrar kontinuerligt säkerhetsfunktioner
            för att minimera risker och uppdaterar kontinuerligt våra system.
            <br />
            <br />
            <strong>Clearly AB värnar om din personliga integritet.</strong>
            <br />
            Det gör vi genom att alltid sträva efter att ha en hög nivå av
            dataskydd. Vår integritetspolicy beskriver hur vi behandlar din
            personliga information och vad du har för rättigheter. Du har alltid
            möjlighet att kontakta oss om du har några frågor. Vi vill att du
            ska känna dig trygg i vår behandling av dina personuppgifter.
            Personuppgifter är all information som direkt eller indirekt kan
            kopplas till en levande fysisk person. Några exempel är foton, namn,
            personnummer, medlemsnummer, ljudfiler och filmer. Även olika slags
            elektroniska enheter, som IP-adresser är personuppgifter om den kan
            kopplas till en person. Behandling av personuppgifter är allt som
            förekommer med personuppgifterna. Varje handling som vidtas med
            personuppgifter utgör en behandling. Exempel på vanliga behandlingar
            är insamling, strukturering, registrering, lagring, bearbetning,
            överföring och radering. Enligt Dataskyddsförordningen är känsliga
            personuppgifter; uppgifter som avslöjar ras eller etniskt ursprung,
            politiska åsikter, religiös eller filosofisk övertygelse, medlemskap
            i fackförening samt personuppgifter som rör hälsa eller sexualliv.
            Vem ansvarar för de personuppgifter som samlas in? Clearly AB är
            personuppgiftsansvarig för företagets behandling av personuppgifter.
            De personuppgifter vi behandlar kommer från något av följande: - Du
            har själv lämnat dem till oss - Dina användaruppgifter i våra system
            - Insamling genom tredje part exempelvis offentliga register
            <br />
            <br />
            <strong>Kan andra komma åt dina personuppgifter?</strong>
            <br />
            Vi delar inte ut dina personuppgifter till någon tredje part. Var
            behandlar vi dina personuppgifter? Alla dina personuppgifter
            hanteras och lagras inom EU. Vi sparar aldrig personuppgifter längre
            än vad som är nödvändigt för respektive syfte. Du har även rätt att
            rätta till dina uppgifter ifall de skulle vara felaktiga. Du har
            rätt att begära radering av dina personuppgifter i följande fall: -
            Om uppgifterna inte längre är nödvändiga för de syften de samlats in
            - Om personuppgifterna behandlas olagligt - Om du invänder mot vår
            intresseavvägning, om din invändning väger tyngre än vårt intresse -
            Om du invänder mot behandling i direktmarknadsföringsändamål Om vi
            har lagliga skyldigheter att hantera dina personuppgifter kan vi
            behöva neka till din begäran om radering. Du har rätt till
            dataportabilitet om behandlingen av dina personuppgifter bygger på
            samtycke. I dessa fall har du rätt att få de uppgifter som rör dig
            som du lämnat till oss överförda till en annan
            personuppgiftsansvarig. Vilka personuppgifter samlar vi in om dig
            och varför? Namn och mejladress. Cookies för att förbättra din
            upplevelse på våra webbplatser Vad är kakor? Kakor eller cookies är
            textfiler som sparas i din webbläsare eller på din dator.
            <br />
            <br />
            <strong>Vilka kakor använder vi?</strong>
            <br />
            Clearly AB använder följande kakor: Sessionskakor, tillfälliga kakor
            som försvinner när du stänger din webbläsare eller dator -
            Beständiga kakor, kakor som ligger kvar på din dator tills du tar
            bort dem eller tills de går ut - Tredjepartskakor, kakor från en
            tredjepart, framförallt för analyser t.ex. Google Analytics. Kakorna
            vi använder är till för att förbättra din upplevelse. Vi använder
            exempelvis kakor för övergripande analys på vår webbplats. Kan du
            själv styra över kakorna? Ja, i din webbläsare kan du ändra
            inställningarna för användningen av kakor. Du kan till exempel
            blockera alla kakor, acceptera bara förstapartskakor eller radera
            kakor när du stänger av din webbläsare.
            <br />
            <br />
            <strong>Kontakt</strong>
            <br />
            Om du har några frågor eller funderingar kan du alltid nå vårt
            dataskyddsombud på Clearly AB, Skärgårdsvägen 158 139 36 Värmdö,
            Sverige. E-post: lina.ylander@clearlyab.se Ändringar kan ske i vår
            integritetspolicy. Den senaste versionen finns alltid att nå på vår
            hemsida. Vid större uppdateringar kommer vi kontakta dig som kund så
            att du alltid vet vad som gäller.
          </p>
        </div>
  </CustomLayout>)
}

export default IndexPage
