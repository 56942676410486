import React, { useState, useEffect } from "react"
/** @jsx jsx */
import { jsx } from "@emotion/core"
import Logo from "../assets/clearly-logo.svg"
import { Link } from "gatsby"
import { MobileRule } from "../constants"
import Hamburger from "../assets/menu.svg"
import Cross from "../assets/close.svg"

const NoAnchorMenu = props => {
  function handleScroll(){
      var scrollY = window.scrollY

      if (scrollY < 40 && !isTop) {
        setIsTop(true)
      } else if (scrollY >= 40 && isTop) {
        setIsTop(false)
      }
  }
  const [showMenu, setShowMenu] = useState(false)
  const [isTop, setIsTop] = useState(true)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    }
  })
  return (
    <div>
      <div
        css={{
          ["a"]: { fontFamily: "Open sans", fontWeight: 600 },
          position: "fixed",
          top: 0,
          zIndex: 2,
          transition: "background-color .25s ease-in-out",
          background: isTop
            ? "white"
            : "linear-gradient(to bottom, rgba(255,255,255,0.8) 70%, rgba(255,255,255,0) 100%)",
          width: "100%",
          [MobileRule]: {
            display: "none",
          },
        }}
      >
        <div
          css={{
            maxWidth: "1260px",
            height: "74px",
            margin: "auto",
            padding: "0.5rem 6.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Link to="/">
            <img
              alt="Logo"
              css={{ width: "100px" }}
              src={Logo}
              onClick={() => setShowMenu(false)}
            />
          </Link>
            <div
              css={{
                padding: "0 0.5rem",
                display: "flex",
                flexWrap: "wrap",
                ["a"]: {
                  textDecoration: "none",
                  marginLeft: "1.3rem",
                  color: "#323943",
                  fontSize: "14px",
                },
              }}
            >
              <Link to="/#redovisning">Redovisning</Link>
              <Link to="/#loneadministration">Löneadministration</Link>
              <Link to="/#radgivning">Rådgivning</Link>
              <Link to="/#vara-kunder">Våra kunder</Link>
              <Link to="/about">Om oss</Link>
              <Link
                to="/#kontakt"
              >
                Kontakt
              </Link>
            </div>
        </div>
      </div>
      <div
        css={{
          display: "none",
          position: "fixed",
          zIndex: 2,
          [MobileRule]: {
            display: "flex",
          },
        }}
      >
        <img
          alt="Menu icon"
          src={showMenu ? Cross : Hamburger}
          onClick={() => setShowMenu(!showMenu)}
          css={{
            position: "absolute",
            left: "1rem",
            top: "1rem",
            width: "25px",
            height: "25px",
          }}
        />
        <Link to="/">
          <img
            alt="Logo"
            css={{
              width: "120px",
              position: "fixed",
              left: "50%",
              top: "1rem",
              transform: "translateX(-50%)",
            }}
            src={Logo}
            onClick={() => setShowMenu(false)}
          />
        </Link>
        {showMenu && (
          <div
            css={{
              height: "100vh",
              width: "100vh",
              background: "rgba(0, 0, 0, 0.3)",
            }}
          >
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    background: "white",
                    height: "100%",
                    paddingTop: "100px",
                    paddingBottom: "1rem",
                    width: "100vw",
                    alignItems: "center",
                    ["a"]: {
                      paddingBottom: "0.5rem",
                      textDecoration: "none",
                      color: "#323943",
                      fontSize: "20px",
                    },
                  }}
                >
                  <Link to="/#redovisning">Redovisning</Link>
                  <Link to="/#loneadministration">Löneadministration</Link>
                  <Link to="/#radgivning">Rådgivning</Link>
                  <Link to="/#vara-kunder">Våra kunder</Link>
                  <Link to="/about">Om oss</Link>
                  <Link
                    to="/#kontakt"
                  >
                    Kontakt
                  </Link>
                </div>
            </div>
        )}
      </div>
    </div>
  )
}

export default NoAnchorMenu
