/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "typeface-open-sans";
import "typeface-poppins"
import { useStaticQuery, graphql } from "gatsby"
import { MobileRule } from "../constants"

import Footer from "./footer"
import "./layout.css"
import NoAchorMenu from "./noAnchorMenu"
import Img from "gatsby-image"

/** @jsx jsx */
import { jsx } from "@emotion/core"
const CustomLayout = (props) => {
  const {children} = props;
  return (
    <>
      <NoAchorMenu />
      <MyHeader />
      <div>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

const MyHeader = (props) => {
    const data = useStaticQuery(graphql`
      query {
        home: file(relativePath: { eq: "header_bg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        about: file(relativePath: { eq: "about_bg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
      }
    `)
    return (
    <header
      css={{
        paddingTop: '89px',
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        [MobileRule]: {
          paddingTop: '40px',
          borderBottom: "none",
        },
        
      }}
    >
      <div
        css={{
          position: "relative",
          margin: `0 auto`,
          textAlign: "center",
          padding: "7.5rem 1.5rem 18rem 1.5rem",
          [MobileRule]: {
            padding: "5rem 2rem 3rem 2rem",
          },
        }}
      >
        <h1
          css={{
            margin: "0 0 2rem 0",
            color: "#FF8479",
            fontWeight: 700,
            fontSize: "4rem",
            lineHeight: "70px",
            maxWidth: '600px',
            textAlign: "center",
            letterSpacing: "0.01em",
            [MobileRule]: {
              fontSize: "20px",
              lineHeight: "26px",
            },
          }}
        >
         GDPR &amp; <br />Integritetspolicy
        </h1>
       
        </div>
      <Img css={{position: 'absolute!important', top: 0, bottom: 0, left: 0, right: 0, zIndex: -1}} fluid={data.home.childImageSharp.fluid} />
    </header>
    )
  }

CustomLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CustomLayout
